import { Component, BaseComponent, Prop } from '@zento-lib/components';
import onEscapePressVSF from '@vue-storefront/core/mixins/onEscapePress';

import { IModal } from './Modal.d';
import style from './style.scss';

/**
 * Modal
 *
 * Modal component allowing to show modal content.
 **/
@Component({
  mixins: [onEscapePressVSF],
})
export class Modal extends BaseComponent<IModal, unknown> {
  @Prop({ type: String, default: '', required: true })
  name: string;

  @Prop({ type: Number, default: 300, required: false })
  delay?: number;

  @Prop({ type: Number, default: 480 })
  width?: number;

  @Prop({ type: Boolean, default: true })
  hasOverlay?: boolean;

  isVisible = false;

  /**
   *  Close modal
   **/
  onHide(name: string) {
    return name === this.name ? this.toggle(false) : false;
  }

  /**
   *  Show modal
   **/
  onShow(name: string) {
    return name === this.name ? this.toggle(true) : false;
  }

  /**
   *  Show toggle modal
   **/
  onToggle(name: string, state: boolean) {
    if (name === this.name) {
      state = typeof state === 'undefined' ? !this.isVisible : state;
      this.toggle(state);
    }
  }

  /**
   *  On escape event hide modal
   **/
  onEscapePress() {
    this.close();
  }

  /**
   *  On toggle event show/hide modal
   **/
  toggle(state: boolean) {
    this.isVisible = state;

    setTimeout(() => {
      this.$store.commit('myAccount/setModalActive', state);

      if (this.hasOverlay) {
        this.$store.commit('ui/setOverlay', state);
      }
    }, this.delay);
  }

  /**
   *  Close modal
   **/
  close() {
    localStorage.removeItem('redirect');
    this.toggle(false);
    document.body.style.overflow = 'visible';
  }

  /**
   *  Prevent user to go the top of the page
   **/
  stopPropagation(ev: Event) {
    ev.stopPropagation();
  }

  beforeMount() {
    this.onBroadcast('modal-toggle', this.onToggle);
    this.onBroadcast('modal-show', this.onShow);
    this.onBroadcast('modal-hide', this.onHide);
  }

  beforeDestroy() {
    this.offBroadcast('modal-toggle', this.onToggle);
    this.offBroadcast('modal-show', this.onShow);
    this.offBroadcast('modal-hide', this.onHide);
  }

  render() {
    return (
      <transition
        name='fade-in-up'
        enterActiveClass={style.fadeInUpEnterActive}
        leaveActiveClass={style.fadeInUpLeaveActive}>
        {this.isVisible ? (
          <div class={style.modal} ref='modal' onClick={this.close} data-testId='Modal'>
            <div class={style.modalWrapper}>
              <div
                class={style.modalContainer}
                ref='modal-content'
                onClick={this.stopPropagation}
                style={{ width: this.width + 'px' }}>
                <div onClick={this.close} slot='close' class={style.modalClose} data-testId='closeModalButton'>
                  <i />
                </div>
                {this.$slots.header ? (
                  <div class={style.modalHeader}>
                    <slot name='header' />
                  </div>
                ) : null}
                {this.$slots.content ? (
                  <div class={style.slotModalContent}>
                    <slot name='content' />
                  </div>
                ) : null}
                <div class={style.modalContent}>
                  <slot />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </transition>
    );
  }
}
